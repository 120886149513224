<template>
    <div>
        <div
            class="relative h-full"
            ref="wrapperRef"
        >
            <div
                class="fixed rounded"
                ref="targetRef"
            ></div>

            <div
                class="w-full rounded pb-12 md:py-12 md:pl-12 lg:fixed"
                data-spec-class="summary"
                ref="summaryRef"
            >
                <BaseButton
                    v-if="!areMobileDetailsVisible"
                    @click="areMobileDetailsVisible = true"
                    class="lg:hidden"
                >
                    {{ $t('configuratorGeneral.showSummary') }}
                </BaseButton>

                <div
                    :class="{
                        'lg:block': true,
                        hidden: !areMobileDetailsVisible,
                    }"
                >
                    <SummaryGroups :summary-groups="summaryGroups"></SummaryGroups>
                </div>
                <SummaryPrices
                    :monthly-price="monthlyPrice"
                    :setup-price="setupPrice"
                    :summary-groups="summaryGroups"
                ></SummaryPrices>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import SummaryGroups from './SummaryGroups.vue';
import SummaryPrices from './SummaryPrices.vue';

const props = defineProps({
    summaryGroups: Array,
    monthlyPrice: { required: true },
    setupPrice: { required: true },
});

const areMobileDetailsVisible = ref(false);
const wrapperRef = ref(null);
const summaryRef = ref(null);
const targetRef = ref(null);

const handleScroll = () => {
    if (summaryRef.value && wrapperRef.value && targetRef.value) {
        const wrapperRect = wrapperRef.value.getBoundingClientRect();
        const targetRect = targetRef.value.getBoundingClientRect();
        updateSummaryDimensions();

        if (wrapperRect.bottom <= targetRect.bottom) {
            summaryRef.value.classList.remove('lg:fixed');
            summaryRef.value.classList.add('lg:absolute', 'bottom-0', 'w-full');
        } else {
            summaryRef.value.classList.remove('lg:absolute', 'bottom-0', 'w-full');
            summaryRef.value.classList.add('lg:fixed');
        }
    }
};

const updateSummaryDimensions = () => {
    summaryRef.value.style.width = `${wrapperRef.value.getBoundingClientRect().width}px`;
    targetRef.value.style.height = `${summaryRef.value.getBoundingClientRect().height}px`;
};

const handleDocumentResize = () => {
    updateSummaryDimensions();
};

onMounted(() => {
    if (!user) {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleDocumentResize);
        updateSummaryDimensions();
    }
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleDocumentResize);
});

const user = document.querySelector('meta[name="user"]').getAttribute('content') || '';
</script>
