import { computed, h } from 'vue';
import VolumeTooltip from './../components/VolumeTooltip.vue';
import { useI18n } from 'vue-i18n';

export function useAssortmentSummary(
    assortment,
    selection,
    monthlyPrices,
    setupPrices,
    numberOfPayedIpv4Addresses
) {
    const { t } = useI18n();

    const uplinkSummary = computed(() => {
        if (!selection.value.uplink) return null;

        return {
            name: selection.value.uplink.name,
            description: null,
            monthlyPrice: monthlyPrices.value.uplink,
            setupPrice: setupPrices.value.uplink,
        };
    });

    const volumeSummary = computed(() => {
        if (!selection.value.volume) return null;

        return {
            name: selection.value.volume.volume + ' Mbit/s',
            description: null,
            monthlyPrice: monthlyPrices.value.volume,
            setupPrice: setupPrices.value.volume,
            component: h(VolumeTooltip, { class: 'ml-1 mb-1' }),
        };
    });

    const ipv4AddressesSummary = computed(() => {
        if (!selection.value.ipv4Addresses) return null;

        return {
            name:
                selection.value.ipv4Addresses +
                ' ' +
                t('configuratorGeneral.ipv4Addresses', {
                    count: selection.value.ipv4Addresses,
                }),
            description:
                numberOfPayedIpv4Addresses.value > 1
                    ? t('configuratorGeneral.ipv4AddressesIncluded', {
                          count: assortment.ipv4Address.free,
                      })
                    : null,
            monthlyPrice: monthlyPrices.value.ipv4Addresses,
            setupPrice: setupPrices.value.ipv4Addresses,
        };
    });

    const ipv4SubnetSummary = computed(() => {
        if (!selection.value.ipv4Subnet) return null;

        return {
            name: selection.value.ipv4Subnet.name + ' ' + t('configuratorGeneral.ipv4Subnet'),
            description: null,
            monthlyPrice: monthlyPrices.value.ipv4Subnet,
            setupPrice: setupPrices.value.ipv4Subnet,
        };
    });

    const ipv6SubnetSummary = computed(() => {
        if (!selection.value.ipv6Subnet) return null;

        return {
            name: selection.value.ipv6Subnet.name + ' ' + t('configuratorGeneral.ipv6Subnet'),
            description: null,
            monthlyPrice: monthlyPrices.value.ipv6Subnet,
            setupPrice: setupPrices.value.ipv6Subnet,
        };
    });

    return {
        uplinkSummary,
        volumeSummary,
        ipv4AddressesSummary,
        ipv4SubnetSummary,
        ipv6SubnetSummary,
    };
}

export function useAssortmentMailSummary(selection) {
    const networkMailSummary = computed(() => {
        return `Netzwerk: ${selection.value.uplink?.name}, ${selection.value.volume?.volume} Mbit/s included`;
    });

    const ipMailSummary = computed(() => {
        const ipSummaries = [];
        if (selection.value.ipv4Addresses > 0) {
            ipSummaries.push(`${selection.value.ipv4Addresses} IPv4-Adressen`);
        }
        if (selection.value.ipv4Subnet) {
            ipSummaries.push(`${selection.value.ipv4Subnet?.name}er IPv4-Netz`);
        }
        if (selection.value.ipv6Subnet) {
            ipSummaries.push(`${selection.value.ipv6Subnet?.name}er IPv6-Netz`);
        }
        return `IP: ${ipSummaries.join(', ')}`;
    });

    return {
        networkMailSummary,
        ipMailSummary,
    };
}
