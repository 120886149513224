<template>
    <div
        :class="classObject"
        :style="styleObject"
    >
        {{ text }}
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { contrastColor } from '../../../../utils/colorUtils';

const props = defineProps({
    text: { type: String, required: true },
    color: { type: String, default: 'green' },
});

const hasDynamicColor = computed(() => {
    return props.color[0] === '#';
});

const classObject = computed(() => {
    return {
        'inline-block rounded-full px-2 py-1 font-sans text-2xs uppercase leading-none': true,
        'bg-green text-black': props.color === 'green' && !hasDynamicColor.value,
        'bg-red text-white': props.color === 'red' && !hasDynamicColor.value,
        'bg-orange-700 text-black': props.color === 'orange' && !hasDynamicColor.value,
        'bg-orange-400 text-black': props.color === 'yellow' && !hasDynamicColor.value,
        'bg-purple-700 text-white': props.color === 'purple' && !hasDynamicColor.value,
        'bg-blue-700 text-black': props.color === 'blue' && !hasDynamicColor.value,
        'bg-gray-200 text-black': props.color === 'gray' && !hasDynamicColor.value,
        'bg-purple-400 text-black': props.color === 'indigo' && !hasDynamicColor.value,
    };
});

const styleObject = computed(() => {
    return {
        backgroundColor: hasDynamicColor.value ? props.color : undefined,
        color: hasDynamicColor.value ? contrastColor(props.color) : undefined,
    };
});
</script>
