<template>
    <div
        class="flex justify-center"
        data-spec-id="configurator--s3"
    >
        <div class="w-full rounded bg-gray-700 py-6 text-white lg:w-2/3 xl:w-1/2">
            <img
                src="/img/icons/23m/s3.svg"
                alt="S3 Object Storage"
                class="mx-auto h-24 w-36"
                data-spec-class="configurator__image"
            />
            <column class="mt-7">
                <price
                    :price="price"
                    :description-per-month="$t('configuratorS3.pricePerMonth')"
                    :further-costs="$t('configuratorS3.furtherCosts')"
                />

                <confirm @click="onClick">
                    {{ $t('configuratorGeneral.continue') }}
                </confirm>
            </column>
        </div>
    </div>
</template>

<script setup>
import Column from './Configurator/Column.vue';
import Price from './Configurator/Price.vue';
import Confirm from './Configurator/Confirm.vue';
import { useAssortmentS3 } from '../composables/assortmentS3';

const props = defineProps({
    assortmentContent: Object,
});

const { price } = useAssortmentS3(props.assortmentContent);

const onClick = () => {
    const configuratorFormId = 'configurator-form--s3';
    const configuratorForm = document.getElementById(configuratorFormId);
    configuratorForm.scrollIntoView({ behavior: 'smooth' });
};
</script>
