export function useAssortment() {
    const feedGoogleTagManager = (form, type) => {
        if (dataLayer && form.type === type && form.price) {
            dataLayer.push({
                event: `${form.type}-submitted`,
                conversionValue: form.price,
            });
        }
    };

    return {
        feedGoogleTagManager,
    };
}
