<template>
    <div class="py-7">
        <div class="flex">
            <div class="w-9">{{ number }}.</div>
            <h3
                class="grow cursor-pointer font-bold text-gray-700"
                @click="open = !open"
            >
                {{ question }}
            </h3>
            <div class="ml-3 flex items-center">
                <svg
                    class="w-3 cursor-pointer transition-all duration-700"
                    :class="{ 'rotate-180': !open, 'text-blue-700': open }"
                    viewBox="0 0 14 8"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    @click="open = !open"
                    stroke-linejoin="round"
                >
                    <line
                        x1="1"
                        y1="2"
                        x2="7"
                        y2="7"
                    />
                    <line
                        x1="7"
                        y1="7"
                        x2="13"
                        y2="2"
                    />
                </svg>
            </div>
        </div>
        <transition
            class="transition-all duration-1000"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <p
                v-show="open"
                class="mt-6 overflow-hidden pl-9 text-gray-400"
                v-html="answer"
            ></p>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        number: {
            type: String,
            required: true,
        },
        question: {
            type: String,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            open: false,
        };
    },
    methods: {
        beforeEnter(el) {
            el.style.height = 0;
            el.style.marginTop = 0;
        },
        enter(el) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.marginTop = null;
        },
        afterEnter(el) {
            el.style.height = null;
        },
        beforeLeave(el) {
            el.style.height = `${el.scrollHeight}px`;
        },
        leave(el) {
            el.style.height = 0;
            el.style.marginTop = 0;
        },
        afterLeave(el) {
            el.style.height = null;
            el.style.marginTop = null;
        },
    },
};
</script>

<style scoped></style>
