<template>
    <div class="text-center">
        <img
            :src="src"
            :alt="alt"
            class="mx-auto w-32"
        />
    </div>
</template>

<script>
export default {
    props: ['src', 'alt'],
};
</script>

<style scoped></style>
