<template>
    <Summary
        :type="AssortmentType.ServerDeals"
        :monthly-price="monthlyPrice"
        :setup-price="setupPrice"
        :summary-groups="summaryGroups"
        :mail-summary="mailSummary"
        :redirectParams="`?redirect=dedicated-server-mieten/confirm&query=${queryParams}`"
        :selection="selection"
        data-spec-id="special-deals-summary"
    >
    </Summary>
</template>

<script setup>
import { computed } from 'vue';
import { useAssortmentSpecialDeal } from '../composables/assortmentSpecialDeal';
import { useAssortmentSummary } from '../composables/assortmentSummary';
import Summary from './Configurator/Summary.vue';
import { AssortmentType } from '../../../types';
import { getQueryParams } from '../../../utils/windowUtils';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const {
    assortment,
    selection,
    numberOfPayedIpv4Addresses,
    monthlyPrice,
    setupPrice,
    monthlyPrices,
    setupPrices,
    mailSummary,
} = useAssortmentSpecialDeal(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);

const { uplinkSummary, volumeSummary, ipv4AddressesSummary, ipv4SubnetSummary, ipv6SubnetSummary } =
    useAssortmentSummary(
        assortment,
        selection,
        monthlyPrices,
        setupPrices,
        numberOfPayedIpv4Addresses
    );

const queryParams = getQueryParams('|');

const summaryGroups = computed(() => {
    const summaries = {};
    if (selection.value.server) {
        summaries.server = {
            name: selection.value.server.name,
            description: selection.value.server.cpu,
            monthlyPrice: selection.value.server.price.monthly_price,
            setupPrice: null,
        };

        summaries.ram = {
            name: selection.value.server.components.ram.name,
        };

        summaries.storages = selection.value.server.components.storages.map(
            (storageQuantityItem) => {
                return {
                    name: `${storageQuantityItem.quantity}x ${storageQuantityItem.name}`,
                };
            }
        );
    }

    summaries.uplink = uplinkSummary.value;
    summaries.volume = volumeSummary.value;
    summaries.ipv4Addresses = ipv4AddressesSummary.value;
    summaries.ipv4Subnet = ipv4SubnetSummary.value;
    summaries.ipv6Subnet = ipv6SubnetSummary.value;

    return Object.values(summaries).filter((summary) => summary);
});
</script>
