<template>
    <div
        class="text-center text-gray-300"
        data-spec-class="configurator-price"
    >
        <div>
            <p class="text-2xl text-blue-700">
                {{ $n(price, 'currency') }}
            </p>
            <p>
                {{ descriptionPerMonthTranslated }}
            </p>
        </div>

        <p
            class="text-green-light"
            v-if="furtherCosts"
        >
            {{ furtherCosts }}
        </p>
        <p
            v-if="setup_price"
            class="mt-3"
        >
            {{
                $t('configuratorGeneral.setupAdditional', { setupFee: $n(setup_price, 'currency') })
            }}
        </p>
        <p class="mt-3 text-sm">({{ $t('configuratorGeneral.pricesPlusVat') }})</p>
    </div>
</template>

<script>
export default {
    props: {
        price: { type: Number, required: true },
        setup_price: { type: Number, default: undefined },
        descriptionPerMonth: { type: String, default: undefined },
        furtherCosts: { type: String, default: undefined },
    },
    computed: {
        descriptionPerMonthTranslated() {
            return this.descriptionPerMonth || this.$t('configuratorGeneral.perMonth');
        },
    },
};
</script>
