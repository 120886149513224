<template>
    <Configurator
        url="/dedicated-server-mieten/confirm"
        v-if="selection.server"
        data-spec-class="special_deals_configurator"
    >
        <img
            src="/img/icons/23m/dedicated-server.svg"
            alt="Dedicated Server"
            class="mx-auto h-24 w-36"
        />
        <Columns>
            <Column>
                <ColumnTitle>Server</ColumnTitle>
                <div class="flex">
                    <SpecialDealsCard
                        :server="selection.server"
                        class="w-full"
                    />
                </div>
            </Column>

            <Column>
                <NetworkConfigurationFieldset
                    :volumes="assortment.volumes"
                    :fields="fields"
                    :selection="selection"
                />

                <IpConfigurationFieldset
                    :assortment="assortment"
                    :fields="fields"
                    :selection="selection"
                />
            </Column>
            <Column>
                <ColumnTitle>{{ $t('configuratorGeneral.price') }}</ColumnTitle>
                <PriceBanner
                    :price="monthlyPrice"
                    :setup-price="setupPrice"
                />
                <Price
                    :price="monthlyPrice"
                    :setup_price="setupPrice"
                />
                <DedicatedAvailability :is-default-config="true" />
                <div>
                    <Hint>{{ $t('configuratorDedicated.contractTerm') }}</Hint>
                    <Hint>{{ $t('configuratorDedicated.noticePeriod') }}</Hint>
                </div>
                <Confirm>{{ $t('configuratorGeneral.continue') }}</Confirm>
            </Column>
        </Columns>

        <div class="mx-6 mt-6 lg:mt-9">
            <BaseLine class="border-gray-500" />
        </div>

        <DedicatedAd />
    </Configurator>

    <div v-else>
        <div
            class="mb-12 flex w-full flex-col items-center rounded bg-gray-700 p-6"
            data-spec-class="special_deals_configurator__no-server"
        >
            <img
                src="/img/icons/23m/dedicated-server.svg"
                alt="Dedicated Server"
                class="mx-auto mb-6 h-24 w-36"
            />
            <span class="text-xl text-gray-300">
                {{ $t('configuratorSpecialDeals.noServerSelectedTitle') }}
            </span>
            <span class="text-lg text-gray-400">
                {{ $t('configuratorSpecialDeals.noServerSelectedDescription') }}
            </span>

            <BaseButton
                class="mt-3"
                href="/dedicated-server-mieten"
                theme="cta"
            >
                {{ $t('configuratorSpecialDeals.noServerSelectedAction') }}
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
import Configurator from './Configurator/Configurator.vue';
import Columns from './Configurator/Columns.vue';
import Column from './Configurator/Column.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Price from './Configurator/Price.vue';
import DedicatedAd from './DedicatedAd.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import Confirm from './Configurator/Confirm.vue';
import Hint from './Configurator/Hint.vue';
import DedicatedAvailability from './DedicatedConfigurator/DedicatedAvailability.vue';
import SpecialDealsCard from './SpecialDealsCard.vue';
import IpConfigurationFieldset from './IpConfigurationFieldset.vue';
import { useAssortmentSpecialDeal } from '../composables/assortmentSpecialDeal';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const { assortment, fields, monthlyPrice, setupPrice, selection } = useAssortmentSpecialDeal(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);
</script>
