<template>
    <BaseUserDropdown :links="links">
        <slot />
    </BaseUserDropdown>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    yourRoute: String,
});

const links = computed(() => [
    {
        id: 'your',
        label: t('menu.customerArea'),
        url: props.yourRoute,
    },
]);
</script>
