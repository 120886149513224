import { reactive } from 'vue';
import { arrayToIndexObject } from '../../../utils/objectUtils';

export function useAssortmentNetwork(assortmentNetworkContent) {
    const networkAssortment = JSON.parse(assortmentNetworkContent);

    const uplinkAssortment = reactive(arrayToIndexObject(networkAssortment.uplinks));

    const trafficAssortment = reactive(
        arrayToIndexObject(
            networkAssortment.traffics.filter((traffic) => traffic.type === 'mbit95')
        )
    );

    return {
        uplinkAssortment,
        trafficAssortment,
    };
}
