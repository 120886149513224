export const chunkArray = (array: any[], chunkSize: number) => {
    if (!Number.isInteger(chunkSize) || chunkSize <= 0) {
        throw new Error('chunkSize must be a positive integer');
    }

    let chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};

export const groupBy = (array, callback) => {
    return array.reduce((acc, obj) => {
        const group = callback(obj);
        acc[group] = acc[group] || [];
        acc[group].push(obj);
        return acc;
    }, {});
};

export const sum = (array) => {
    return array.reduce((accumulator, value) => accumulator + Number(value), 0);
};
