/* eslint-disable import/prefer-default-export */

let price = 0;

export function useAssortmentS3(assortmentContent) {
    const parsedJson = JSON.parse(assortmentContent);

    if (parsedJson.pricePerGB) {
        price = Number(parsedJson.pricePerGB);
    }

    return { price };
}
