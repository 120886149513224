<template>
    <Summary
        :type="AssortmentType.Cloud"
        :monthly-price="price"
        :setup-price="setupPrice"
        :summary-groups="summaryGroups"
        :mail-summary="mailSummary"
        :redirectParams="`?redirect=cloud-server/confirm&query=${queryParams}`"
        data-spec-id="cloud-summary"
    />
</template>

<script setup>
import { computed } from 'vue';
import Summary from './Configurator/Summary.vue';
import { useI18n } from 'vue-i18n';
import { UseCloudConfiguration } from '../functions/useCloudConfiguration';
import { AssortmentType } from '../../../types';
import { getQueryParams } from '../../../utils/windowUtils';

const { t } = useI18n();

const {
    state,
    selectedManagement,
    selectedNetwork,
    selectedVolume,
    price,
    setupPrice,
    mailSummary,
} = UseCloudConfiguration();

const queryParams = getQueryParams('|');

const summaryGroups = computed(() => {
    const summaries = {};

    if (selectedManagement.value) {
        summaries.management = {
            name: selectedManagement.value.name,
            description: null,
            monthlyPrice: selectedManagement.value.price,
            setupPrice: selectedManagement.value.setupPrice,
        };
    }

    if (state.selected.cpu) {
        summaries.cpu = {
            name: `${state.selected.cpu}x ${t('general.cpu', state.selected.cpu)}`,
            description: null,
            monthlyPrice: state.selected.cpu * state.assortment.cpu.price,
            setupPrice: null,
        };
    }

    if (state.selected.ram) {
        summaries.ram = {
            name: `${state.selected.ram} GB RAM`,
            description: null,
            monthlyPrice: state.selected.ram * state.assortment.ram.price,
            setupPrice: null,
        };
    }

    if (state.selected.storage) {
        summaries.storage = {
            name: `${state.selected.storage} GB Storage`,
            description: null,
            monthlyPrice: state.selected.storage * state.assortment.storage.price,
            setupPrice: null,
        };
    }

    if (selectedNetwork.value) {
        summaries.network = {
            name: selectedNetwork.value.name,
            description: null,
            monthlyPrice: selectedNetwork.value.network?.price,
            setupPrice: null,
        };
    }

    if (selectedVolume.value) {
        summaries.volume = {
            name: selectedVolume.value.volume + ' Mbit/s',
            description: null,
            monthlyPrice: selectedVolume.value.price,
            setupPrice: null,
        };
    }

    return Object.values(summaries);
});
</script>
