<template>
    <div
        class="flex !w-full items-center justify-center"
        data-spec-class="summary-user"
    >
        <div class="rounded bg-gray-700 px-12 py-6 text-white shadow lg:w-1/2">
            <UserInfoLoggedIn
                :user-name="userName"
                :redirect-params="redirectParams"
            />

            <SummaryGroups :summary-groups="summaryGroups"></SummaryGroups>
            <SummaryPrices
                :summary-groups="summaryGroups"
                :monthly-price="monthlyPrice"
                :setup-price="setupPrice"
            ></SummaryPrices>

            <form @submit.prevent="onSubmit">
                <Confirm
                    class="mt-6 flex justify-center"
                    :disabled="sendingStatus.busy || sendingStatus.success"
                >
                    <BaseSpinner
                        v-if="sendingStatus.busy || sendingStatus.success"
                        class="mr-0.5"
                    />
                    <span data-spec-class="confirmation-form__submit--order">
                        {{ t('configuratorGeneral.orderButton') }}
                    </span>
                </Confirm>
            </form>
        </div>
    </div>
</template>

<script setup>
import { defineProps, reactive } from 'vue';
import SummaryGroups from './SummaryGroups.vue';
import SummaryPrices from './SummaryPrices.vue';
import Confirm from './Confirm.vue';
import { UseFormSubmit } from '../../functions/useFormSubmit';
import { useI18n } from 'vue-i18n';
import { useAssortment } from '../../composables/assortment';
import UserInfoLoggedIn from '../UserInfoLoggedIn.vue';

const { t } = useI18n();
const { feedGoogleTagManager } = useAssortment();

const props = defineProps({
    summaryGroups: Array,
    userName: String,
    type: { required: true },
    monthlyPrice: { required: true },
    setupPrice: { required: true },
    mailSummary: { required: true },
    selection: { required: true },
    redirectParams: { default: '' },
});

const submitData = reactive({
    configuration: props.mailSummary,
    type: props.type,
    price: props.monthlyPrice,
    setupPrice: props.setupPrice,
    selection: props.selection,
    lang: document.documentElement.lang,
});

const { send, sendingStatus } = UseFormSubmit(
    window.location.href + props.urlModifier,
    submitData,
    () => feedGoogleTagManager(submitData, props.type)
);

const onSubmit = async () => {
    try {
        await send();
        window.location.href = '/thank-you';
    } catch (error) {}
};
</script>
