<template>
    <configurator url="/cloud-server/confirm">
        <img
            src="/img/icons/23m/cloud.svg"
            alt="Cloud Server"
            class="mx-auto h-24 w-36"
        />
        <columns>
            <column>
                <column-title>Management</column-title>
                <radios>
                    <template v-for="({ name }, id) in state.assortment.managements">
                        <radio-box
                            v-model.number="state.selected.management"
                            name="management"
                            :value="id"
                            :title="name"
                        />
                    </template>
                </radios>
            </column>
            <column>
                <column-title>Performance</column-title>
                <range-input
                    v-model.number="state.selected.cpu"
                    label="CPU"
                    name="cpu"
                    unit="St."
                    :min="state.assortment.cpu.min"
                    :max="state.assortment.cpu.max"
                />
                <range-input
                    v-model.number="state.selected.ram"
                    label="RAM"
                    name="ram"
                    unit="GB"
                    :min="state.assortment.ram.min"
                    :max="state.assortment.ram.max"
                />
                <range-input
                    v-model.number="state.selected.storage"
                    label="Storage"
                    name="storage"
                    unit="GB"
                    :min="state.assortment.storage.min"
                    :max="state.assortment.storage.max"
                />
            </column>
            <column>
                <column-title>{{ $t('configuratorGeneral.network') }}</column-title>
                <radios>
                    <template v-for="(network, id) in state.assortment.networks">
                        <radio
                            v-model="state.selected.network"
                            :label="network.name"
                            :value="id"
                            name="network"
                        />
                    </template>
                </radios>
                <div class="space-y-3">
                    <dropdown
                        v-model="state.selected.volume"
                        name="volume"
                        :label="$t('configuratorCloudserver.includedVolume')"
                        :options="volumeList"
                    />
                    <hint>{{ $t('configuratorCloudserver.eachTb') }}</hint>
                </div>
            </column>
            <column>
                <column-title>{{ $t('configuratorGeneral.price') }}</column-title>
                <PriceBanner
                    :price="price"
                    :setup_price="setupPrice"
                />
                <price
                    :price="price"
                    :setup_price="setupPrice"
                />
                <availability>{{ $t('configuratorGeneral.available8hrs') }}</availability>
                <div>
                    <hint>{{ $t('configuratorCloudserver.contractTerm') }}</hint>
                    <hint>{{ $t('configuratorCloudserver.noticePeriod') }}</hint>
                </div>
                <confirm>{{ $t('configuratorGeneral.continue') }}</confirm>
            </column>
        </columns>
    </configurator>
</template>

<script>
import Configurator from './Configurator/Configurator.vue';
import Icon from './Configurator/Icon.vue';
import Columns from './Configurator/Columns.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Column from './Configurator/Column.vue';
import Radios from './Configurator/Radios.vue';
import RadioBox from './Configurator/RadioBox.vue';
import Range from './Configurator/Range.vue';
import Price from './Configurator/Price.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import Availability from './Configurator/Availability.vue';
import Confirm from './Configurator/Confirm.vue';
import RangeInput from './Configurator/RangeInput.vue';
import { UseCloudConfiguration } from '../functions/useCloudConfiguration';
import Radio from './Configurator/Radio.vue';
import Dropdown from './Configurator/Dropdown.vue';
import { mapAttribute, mapSubnetAttribute } from '../assortments/AssortmentManager';
import Hint from './Configurator/Hint.vue';

export default {
    components: {
        Hint,
        Dropdown,
        Radio,
        RangeInput,
        Confirm,
        Availability,
        Price,
        PriceBanner,
        Range,
        RadioBox,
        Radios,
        Column,
        ColumnTitle,
        Columns,
        Icon,
        Configurator,
    },
    setup() {
        return {
            ...UseCloudConfiguration(),
        };
    },
    computed: {
        volumeList() {
            return mapAttribute(this.state.assortment.volumes, 'volume', ' TB');
        },
    },
};
</script>
