import { computed, reactive } from 'vue';
import { getAllUrlParams } from '../../../utils/windowUtils';
import { cpu, managements, ram, storage } from '../assortments/CloudAssortment';
import { networks, volumesCloud } from '../assortments/NetworkAssortment';
import { pickByIds } from '../assortments/AssortmentManager';
import _max from 'lodash/max';
import _sum from 'lodash/sum';
import _join from 'lodash/join';
import _entries from 'lodash/entries';
import _isUndefined from 'lodash/isUndefined';

export function UseCloudConfiguration() {
    const state = reactive({
        selected: {
            management: 1,
            cpu: 1,
            ram: 4,
            storage: 25,
            network: 1,
            volume: 1,
        },
        assortment: {
            managements: managements(),
            cpu: cpu(),
            ram: ram(),
            storage: storage(),
            networks: pickByIds(networks(), [1]),
            volumes: volumesCloud(),
        },
    });

    const selectedManagement = computed(
        () => state.assortment.managements[state.selected.management]
    );

    const selectedNetwork = computed(() => state.assortment.networks[state.selected.network]);

    const selectedVolume = computed(() => state.assortment.volumes[state.selected.volume]);

    const price = computed(() => {
        return _sum([
            selectedManagement.value.price,
            state.selected.cpu * state.assortment.cpu.price,
            state.selected.ram * state.assortment.ram.price,
            state.selected.storage * state.assortment.storage.price,
            selectedVolume.value.price,
        ]);
    });

    const setupPrice = computed(() => {
        return selectedManagement.value.setupPrice;
    });

    const summary = computed(() => {
        const managementSummary = selectedManagement.value.name;
        const cpuSummary = `${state.selected.cpu} CPU-Kerne`;
        const ramSummary = `${state.selected.ram} GB RAM`;
        const storageSummary = `${state.selected.storage} GB Storage`;
        const networkSummary = `Network: ${selectedNetwork.value.name}, ${selectedVolume.value.volume} TB included`;
        const ipSummaries = [];

        return _join(
            [managementSummary, cpuSummary, ramSummary, storageSummary, networkSummary],
            '\n'
        );
    });

    const loadConfigFromUrlParams = () => {
        const urlParams = getAllUrlParams();
        for (const [key, value] of _entries(urlParams)) {
            if (_isUndefined(state.selected[key])) {
                continue;
            }
            state.selected[key] = value;
        }
    };

    loadConfigFromUrlParams();

    return {
        state,
        selectedManagement,
        selectedNetwork,
        selectedVolume,
        price,
        setupPrice,
        summary,
    };
}
