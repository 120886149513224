import { sum } from '../../../utils/arrayUtils';
import { reactive, computed } from 'vue';
import { getAllUrlParams } from '../../../utils/windowUtils';
import { useAssortmentMailSummary } from './assortmentSummary';
import { useAssortmentNetwork } from './assortmentNetwork';
import { useAssortmentIp } from './assortmentIp';
import { groupBy } from '../../../utils/arrayUtils';
import { translateNameI18n } from '../../../utils/i18nUtils';

export function useAssortmentSpecialDeal(
    assortmentContent,
    assortmentNetworkContent,
    assortmentIpContent
) {
    const { trafficAssortment } = useAssortmentNetwork(assortmentNetworkContent);
    const {
        ipV4NetworkAssortment,
        ipV6NetworkAssortment,
        ipV4AddressAssortment,
        getNumberOfPayedIpv4Addresses,
    } = useAssortmentIp(assortmentIpContent);

    const fields = reactive({
        serverId: null,

        volumeId: 1,
        ipv4Addresses: 1,
        ipv4Subnet: null, // TODO: change to ipv4SubnetId when all configurators are updated
        ipv6Subnet: null, // TODO: change to ipv6SubnetId when all configurators are updated
    });

    const assortment = reactive({
        servers: [],

        volumes: [],
        ipv4Address: {},
        ipv4Subnets: [],
        ipv6Subnets: [],
    });

    const selection = computed(() => {
        const server = assortment.servers.find(
            (server) => String(server.id) === String(fields.serverId)
        );

        return {
            server: server,

            uplink: server?.uplink,
            volume: assortment.volumes[fields.volumeId],
            ipv4Addresses: fields.ipv4Addresses,
            ipv4Subnet: assortment.ipv4Subnets[fields.ipv4Subnet] || null,
            ipv6Subnet: assortment.ipv6Subnets[fields.ipv6Subnet] || null,
        };
    });

    const numberOfPayedIpv4Addresses = computed(() =>
        getNumberOfPayedIpv4Addresses(selection.value.ipv4Addresses)
    );

    const monthlyPrices = computed(() => {
        const prices = {};

        const server = selection.value.server;
        prices.server = server.price.monthly_price;

        prices.volume = selection.value.volume?.monthly_price ?? 0;
        prices.ipv4Addresses = numberOfPayedIpv4Addresses.value * assortment.ipv4Address.price;
        prices.ipv4Subnet = selection.value.ipv4Subnet?.monthly_price ?? 0;
        prices.ipv6Subnet = selection.value.ipv6Subnet?.monthly_price ?? 0;

        return prices;
    });

    const monthlyPrice = computed(() => {
        return sum(Object.values(monthlyPrices.value));
    });

    const setupPrices = computed(() => {
        const prices = {};

        prices.volume = selection.value.volume?.setup_price ?? 0;
        prices.ipv4Addresses = numberOfPayedIpv4Addresses.value * assortment.ipv4Address.setupPrice;
        prices.ipv4Subnet = selection.value.ipv4Subnet?.setup_price ?? 0;
        prices.ipv6Subnet = selection.value.ipv6Subnet?.setup_price ?? 0;
        return prices;
    });

    const setupPrice = computed(() => {
        return sum(Object.values(setupPrices.value));
    });

    const initAssortment = (serverAssortment) => {
        assortment.servers = serverAssortment.sort((a, b) => a.sort - b.sort);

        assortment.volumes = trafficAssortment;
        assortment.ipv4Address = ipV4AddressAssortment;
        assortment.ipv4Subnets = ipV4NetworkAssortment;
        assortment.ipv6Subnets = ipV6NetworkAssortment;
    };

    const initFields = (server) => {
        fields.serverId = server.id;
    };

    const initFieldsByUrl = () => {
        const urlParams = getAllUrlParams();
        if (urlParams.server) {
            fields.serverId = parseInt(urlParams.server);
        }

        if (urlParams.volume) {
            fields.volumeId = parseInt(urlParams.volume);
        }

        if (urlParams.ipv4Addresses) {
            fields.ipv4Addresses = parseInt(urlParams.ipv4Addresses);
            fields.ipv4Subnet = null;
            fields.ipv6Subnet = null;
        }
        if (urlParams.ipv4Subnet) {
            fields.ipv4Subnet = parseInt(urlParams.ipv4Subnet);
            fields.ipv4Addresses = 0;
        }
        if (urlParams.ipv6Subnet) {
            fields.ipv6Subnet = parseInt(urlParams.ipv6Subnet);
            fields.ipv4Addresses = 0;
        }
    };

    const mailSummary = computed(() => {
        const summaries = {};
        summaries.server = `Server: #${selection.value.server.id} ${selection.value.server.name} (${selection.value.server.cpu})`;
        summaries.ram = `RAM: ${selection.value.server.components.ram.name}`;

        const storageQuantityItemSummaries = selection.value.server.components.storages.map(
            (storageQuantityItem) => {
                return `${storageQuantityItem.quantity}x ${storageQuantityItem.name}`;
            }
        );
        summaries.storages = `Storages: ${storageQuantityItemSummaries.join(', ')}`;

        const { networkMailSummary, ipMailSummary } = useAssortmentMailSummary(selection);
        summaries.network = networkMailSummary.value;
        summaries.ip = ipMailSummary.value;

        return Object.values(summaries).join('\n');
    });

    const serverAssortment = JSON.parse(assortmentContent);

    initAssortment(serverAssortment);
    initFields(serverAssortment[0]);
    initFieldsByUrl();

    const decorateCpuSpecs = (cpu) => {
        return `${cpu.cores} Cores, ${cpu.threads} Threads, ${cpu.frequency} GHz`;
    };

    const getPermanentStorage = (id) => {
        return assortment.permanentStorages[id] || null;
    };

    const getPermanentStorages = (permanentStorages) => {
        return Object.entries(permanentStorages).map(([key, value]) => {
            return {
                quantity: value,
                storage: getPermanentStorage(key),
            };
        });
    };

    const getTraffic = (id) => {
        return assortment.networks[id] || null;
    };

    const badges = computed(() => {
        const allBadges = assortment.servers.map((server) => server.badges);

        const uniqueBadges = allBadges
            .flat()
            .filter((badge, index, self) => index === self.findIndex((t) => t.id === badge.id))
            .sort((a, b) => a.sort - b.sort);

        translateNameI18n(uniqueBadges);

        return uniqueBadges;
    });

    const groupedBadges = computed(() => {
        return groupBy(badges.value, (badge) => {
            return badge.group_id;
        });
    });

    return {
        assortment,
        fields,
        selection,
        decorateCpuSpecs,
        numberOfPayedIpv4Addresses,
        monthlyPrices,
        monthlyPrice,
        setupPrices,
        setupPrice,
        mailSummary,
        getTraffic,
        badges,
        groupedBadges,
    };
}
