export default {
    privacy: 'Please note our <a class="hover:underline" href="/en/privacy">privacy notice</a>.',
    honeypot: 'Please leave this field empty (spam protection)',
    errors: {
        tooManyRequests: 'You have sent too many requests. Please try again later.',
        error: 'There was an error. Please try again.',
    },
    contact: {
        name: 'Name',
        email: 'Mail',
        concern: 'Your request',
        company: 'Company',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone number',
        message: 'Your message',
        send: 'Send',
        loading: 'Loading ...',
    },
    configuratorGeneral: {
        network: 'Network',
        ipConfiguration: 'IP configuration',
        or: 'or',
        perMonth: 'monthly',
        plusVat: 'plus VAT',
        pricesPlusVat: 'Prices plus VAT',
        price: 'Price',
        setupAdditional: 'plus { setupFee } Setup (one-time)',
        phoneNumber: 'Phone number',
        furtherRemarks: 'Further remarks',
        requestHeader: 'Non-binding request',
        requestText:
            'You make a non-binding request. We will contact you as soon as possible after receipt.',
        requestButton: 'Non-binding request',
        available8hrs: 'Available in 8 hours',
        available48hrs: 'Available in 48 hours',
        mbIncluded: 'Mbit/s included',
        ipv4Addresses: 'IPv4–Address | IPv4–Addresses',
        ipv4AddressesIncluded:
            '1 free IPv4–Address included | {count} free IPv4–Addresses included',
        includedBandwidth: 'Included–Bandwidth (95 %)',
        includedBandwidthHint:
            'With the 95% method, five per cent of the measurement points are removed so that short-term traffic peaks are not calculated.',
        eachMbit: 'Each further Mbit €0.35',
        ipv4Subnet: 'IPv4–Subnet',
        ipv6Subnet: 'IPv6–Subnet',
        continue: 'Continue',
        included: 'inclusive',
        alreadyACustomer: 'You are already a customer with us?',
        loginHere: 'Log in here!',
        notYou: 'Not you?',
        summaryTitle: 'Your configuration',
        showSummary: 'Show details',
        orderButton: 'Order now',
        orderSuccess: 'Thank you very much! We have received your order.',
        exclusivelyForTradespeople: 'Our offers are exclusively for tradespeople.',
    },
    configuratorDedicated: {
        base: 'Base',
        fixed: 'Fixed',
        slotsRemaining:
            'no slot available | {count} slot still available | {count} slots still available',
        continue: 'Continue',
        contractTerm: 'Contract period: 1 month',
        noticePeriod: 'Notice period: 2 weeks',
        specialDealsAdTitle: 'Not the right one?',
        specialDealsAdDescription:
            'Would you like to upgrade your infrastructure and save money in the process? Take a look at our <a class="text-blue-700 underline" href="{href}">cost-effective pre-configured dedicated servers</a>!',
    },
    configuratorSpecialDeals: {
        base: 'Base',
        fixed: 'Fixed',
        slotsRemaining:
            'no slot available | {count} slot still available | {count} slots still available',
        continue: 'Continue',
        contractTerm: 'Contract period: 1 month',
        noticePeriod: 'Notice period: 2 weeks',
        dedicatedAdTitle: 'Not the right one?',
        dedicatedAdDescription:
            'Do you need more flexibility and want to <a class="text-blue-700 underline" href="{hrefDedicated}"> configure your dedicated server yourself </a>? Then visit our configurator! Our sales team will be happy to help you with any other special requests. <a class="text-blue-700 underline" href="{hrefContact}">Contact</a> us now!',
        noServerSelectedTitle: 'Server is no longer available',
        noServerSelectedDescription: 'Return to the server selection and select another server.',
        noServerSelectedAction: 'To server selection',
    },
    configuratorColocation: {
        forOneDevice: 'For 1 Device',
        unit: 'U',
        lockable: 'Lockable',
        base: 'Base',
        power: 'Power',
        forSingleDevice: 'For 1 Device',
        powerSupply: 'Power supply 230 V ',
        fuse: 'Fuse protection per feed {fuseProtection}',
        electricalPower: 'Power provisioning',
        electricalPowerIncluded: 'free {count} W included',
        eachFurther: 'each further',
        contractTerm: 'Contract period: 3 months',
        noticePeriod: 'Notice period: 1 month',
        eachKwh: 'Electricity price per kWh €0.35',
        mbsIncluded: '250 Mbit/s included',
        heightUnit: 'Unit | Units',
    },
    configuratorCloudserver: {
        includedVolume: 'Included-Volume',
        contractTerm: 'Contract period: 1 month',
        noticePeriod: 'Notice period: 2 weeks',
        eachTb: 'Each further TB €5.00',
    },
    configuratorS3: {
        pricePerMonth: 'per GB / per month',
        furtherCosts: 'No further costs!',
        orderHint:
            'After submitting your order, you will receive the access data for your S3 Object Storage within a few minutes.',
    },
    confirmationGeneral: {
        termsOfPayment: 'Payable within 14 days of invoicing by bank transfer.',
    },
    menu: {
        customerArea: 'Customer area',
        logout: 'Sign out',
    },
    imageDescriptions: {
        'ms-a_jpg': 'Image of an office with meetingroom and cozy sitting corner.',
        'ms-b-jpg':
            'Image of a coworker sitting at his desk with a macbook while checking his phone.',
        'ms-c-jpg': 'Image of a coworking space in front of a whiteboard.',
        'ms-d-jpg': 'Image of a coworker with his mac.',
        'ms-e-jpg': 'Image of a coworker writing something on a whiteboard.',
        'dc-a-jpg': 'Image of a server cage with full server racks on both sides.',
        'dc-b-jpg': 'Image of the doors to our server cage with the 23M logo on them.',
        'dc-c-jpg': 'Image of cables on the back of a rack, connecting several switches.',
        'dc-d-jpg': 'Image of different network cables in switches.',
        'dc-e-jpg': 'Image of different storage ports on a server.',
        'dc-f-jpg': 'Closeup shot of server power supplies.',
        'data-center-jpg':
            'Drone shot of our data center with Frankfurts skyline in the background.',
        'security-jpg': 'Image of security cameras observing our data center.',
        'power-supply-jpg': 'Image of the redundant energy generators for our data center.',
        'cooling-jpg':
            'Image of the air conditioning system on the roof of our data center with parks in the background.',
        'fire-protection-jpg':
            'Image of the fire extinguishing gas for the safety system in our data center.',
        'network-jpg': 'Image of multiple network and fibeeglass cables.',
        'ms-jpg': 'Image of two colleagues working together at a computer.',
        'ms-big-jpg': 'Image of a coworker showing a presentation on his laptop.',
        'ffm-jpg': 'Image of two colleagues working together at a server rack.',
        'ffm-big-jpg': 'Image of a colleague sitting on the floor in front of our server rack.',
        'techie-jpg': 'Image showing the hand of a person swapping the hard drive in a server.',
        'dev-jpg': 'Image of a developer working on his code.',
        'team-event-jpg': 'Group image with out colleagues during a company event.',
        'team-party-jpg':
            'Image of four colleagues during a party in our office, showing different lights and a dj-controller.',
        'contact-ms-jpg': 'Image showing the outside of our office in Münster.',
        'contact-ffm-jpg':
            'Image showing the outside and security fence of our data center in Frankfurt.',
    },
    newsletter: {
        subscribe: 'Subscribe to the newsletter',
        success: 'Thank you very much! We have sent you a mail.',
        error: 'Unfortunately, this did not work. Please try again.',
    },
    forms: {
        optional: 'Optional',
        customer: {
            first_name: {
                label: 'First name',
                placeholder: '',
            },
            last_name: {
                label: 'Last name',
                placeholder: '',
            },
            email: {
                label: 'Mail',
                placeholder: '',
            },
            company: {
                label: 'Company',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            vat_id: {
                label: 'VAT ID',
                placeholder: '',
            },
            has_agreed_terms: {
                label: 'I agree to the <a class="hover:underline" href="/en/privacy" target="_blank">Privacy Policy</a> and the <a class="hover:underline" href="/en/standard-terms-of-business" target="_blank">Standard Terms of Business</a>.',
            },
        },
        addressDach: {
            street: {
                label: 'Street',
                placeholder: '',
            },
            number: {
                label: 'House number',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            zip: {
                label: 'Zip code',
                placeholder: '',
            },
            city: {
                label: 'City',
                placeholder: '',
            },
            additional: {
                label: 'Additional',
                placeholder: '',
            },
        },
        addressNonDach: {
            street: {
                label: 'Street & house number',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            city: {
                label: 'Zip code & city',
                placeholder: '',
            },
            additional: {
                label: 'Additional',
                placeholder: '',
            },
        },
    },
};
