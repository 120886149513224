import { reactive } from 'vue';
import { arrayToIndexObject } from '../../../utils/objectUtils';

export function useAssortmentIp(assortmentIpContent) {
    const ipAssortment = JSON.parse(assortmentIpContent);

    const ipV4NetworkAssortment = reactive(arrayToIndexObject(ipAssortment.ipv4_networks));

    const ipV6NetworkAssortment = reactive(arrayToIndexObject(ipAssortment.ipv6_networks));

    function convertIpArrayToObject(ipArray) {
        const sizes = ipArray.map((ip) => ip.size);
        const maxSize = Math.max(...sizes);

        const freeIpEntry = ipArray.find((ip) => ip.monthly_price === 0);
        const priceEntry = ipArray.find((ip) => ip.size === freeIpEntry.size + 1);

        // TODO: change keys after all configurators are updated
        return {
            price: parseFloat(priceEntry.monthly_price),
            setupPrice: parseFloat(priceEntry.setup_price),
            min: 0,
            max: maxSize,
            free: freeIpEntry.size,
        };
    }

    const ipV4AddressAssortment = reactive(convertIpArrayToObject(ipAssortment.ipv4_addresses));

    const getNumberOfPayedIpv4Addresses = (selectedIpv4Addresses) =>
        Math.max(...[0, selectedIpv4Addresses - ipV4AddressAssortment.free]);

    return {
        ipV4AddressAssortment,
        ipV4NetworkAssortment,
        ipV6NetworkAssortment,
        getNumberOfPayedIpv4Addresses,
    };
}
