<template>
    <ConfirmationForm
        :type="AssortmentType.ServerDeals"
        :price="monthlyPrice"
        :setup-price="setupPrice"
        :configuration="mailSummary"
        :selection="selection"
        :redirectParams="`?redirect=dedicated-server-mieten/confirm&query=${queryParams}`"
    />
</template>

<script setup>
import ConfirmationForm from './ConfirmationForm.vue';
import { getQueryParams } from '../../../utils/windowUtils';
import { useAssortmentSpecialDeal } from '../composables/assortmentSpecialDeal';
import { AssortmentType } from '../../../types';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const queryParams = getQueryParams('|');
const { monthlyPrice, setupPrice, mailSummary, selection } = useAssortmentSpecialDeal(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);
</script>
