<template>
    <div
        ref="column"
        class="flex-auto space-y-6 p-6"
        :style="style"
    >
        <slot />
    </div>
</template>

<script>
export default {
    data() {
        return {
            style: '',
        };
    },
    mounted() {
        this.makeWidthFixed();
        window.addEventListener('resize', () => {
            this.makeWidthFixed();
        });
    },
    methods: {
        makeWidthFixed() {
            this.style = '';
            this.$nextTick(() => {
                const width = this.$refs.column.clientWidth;
                this.style = `width: ${width}px`;
            });
        },
    },
};
</script>
