<template>
    <div class="text-center">
        <div
            :class="
                soon
                    ? 'border-green bg-green-dark text-green'
                    : 'border-gray-400 bg-gray-600 text-gray-400'
            "
            class="inline-block rounded-full border px-3 py-1 text-sm"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        soon: { type: Boolean, default: true },
    },
};
</script>
