<template>
    <div>
        <template v-if="label">
            <p class="text-left text-lg text-white">
                {{ label }}
            </p>
        </template>
        <div class="flex items-center space-x-3">
            <input
                type="range"
                :disabled="disabled"
                :name="name"
                :value="modelValue"
                :min="min"
                :max="max"
                class="grow"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <div class="w-2 text-xl text-white">
                {{ modelValue }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        modelValue: { default: null },
        label: { type: String, required: false },
        unit: { type: String, default: '' },
        min: { type: Number, default: 1 },
        max: { type: Number, required: true },
        disabled: { type: Boolean, default: false },
    },
};
</script>
