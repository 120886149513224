<template>
    <div ref="viewportElement" />
    <TransitionRoot
        data-spec-class="banner-fixed"
        v-bind="$attrs"
        :show="isBannerVisible"
        class="container fixed inset-x-0 bottom-0 block lg:hidden"
        enter="transition-opacity duration-75"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <slot />
    </TransitionRoot>
</template>

<script>
import { TransitionRoot } from '@headlessui/vue';

export default {
    components: { TransitionRoot },
    data() {
        return {
            isBannerVisible: true,
        };
    },
    mounted() {
        document.addEventListener('scroll', this.handleScrollEvent, { passive: true });
    },
    unmounted() {
        document.removeEventListener('scroll', this.handleScrollEvent, { passive: true });
    },
    methods: {
        handleScrollEvent() {
            const { viewportElement } = this.$refs;

            this.hasElementPassedViewportBottom(viewportElement)
                ? (this.isBannerVisible = false)
                : (this.isBannerVisible = true);
        },
        hasElementPassedViewportBottom(element) {
            return (
                element.getBoundingClientRect().bottom <=
                (window.innerHeight || document.documentElement.clientHeight)
            );
        },
    },
};
</script>
