<template>
    <form
        @submit.prevent="onSubmit"
        data-spec-id="confirmation-form"
        class="w-full"
        v-if="!user || isUsableForUser"
    >
        <div class="flex flex-col items-center rounded bg-gray-700 py-12 text-white">
            <div
                v-if="!user"
                class="lg:w-2/3"
            >
                <div
                    class="mb-6 px-6"
                    data-spec-class="confirmation-form__already-a-customer"
                >
                    {{ $t('configuratorGeneral.alreadyACustomer') }}
                    <a
                        class="underline"
                        :href="`/login${redirectParams}`"
                        data-spec-class="confirmation-form__login-link"
                    >
                        {{ $t('configuratorGeneral.loginHere') }}
                    </a>
                </div>

                <div class="grid grid-cols-12 gap-x-3 gap-y-9 p-6 text-left sm:p-0">
                    <div class="col-span-6">
                        <BaseFieldInput
                            v-model="form.first_name"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`forms.customer.first_name.label`)"
                            :vuelidate-field="v$.first_name"
                            name="first_name"
                        />
                    </div>

                    <div class="col-span-6">
                        <BaseFieldInput
                            v-model="form.last_name"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`forms.customer.last_name.label`)"
                            :vuelidate-field="v$.last_name"
                            name="last_name"
                        />
                    </div>

                    <div class="col-span-12">
                        <BaseFieldInput
                            v-model="form.email"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`forms.customer.email.label`)"
                            :vuelidate-field="v$.email"
                            name="email"
                        />
                    </div>

                    <div class="col-span-12">
                        <BaseFieldInput
                            v-model="form.company"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`forms.customer.company.label`)"
                            :vuelidate-field="v$.company"
                            :hint="t(`configuratorGeneral.exclusivelyForTradespeople`)"
                            name="company"
                        />
                    </div>

                    <div class="col-span-12">
                        <BaseFieldSelectSearch
                            v-model="form.country_code"
                            :custom-theme-base="FormTheme.Website"
                            :options="countryStore.getCountriesAsOptions()"
                            :label="t(`${i18nCodePrefix}.country.label`)"
                            :vuelidate-field="v$.country_code"
                            name="country_code"
                        />
                    </div>

                    <div
                        v-if="form.country_code"
                        :class="{ 'col-span-8': isDach, 'col-span-12': !isDach }"
                    >
                        <BaseFieldInput
                            v-model="form.street"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`${i18nCodePrefix}.street.label`)"
                            :placeholder="t(`${i18nCodePrefix}.street.placeholder`)"
                            :vuelidate-field="v$.street"
                            name="street"
                        />
                    </div>

                    <div
                        v-if="isDach && form.country_code"
                        class="col-span-4"
                    >
                        <BaseFieldInput
                            v-model="form.number"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`${i18nCodePrefix}.number.label`)"
                            :placeholder="t(`${i18nCodePrefix}.number.placeholder`)"
                            :vuelidate-field="v$.number"
                            name="number"
                        />
                    </div>

                    <div
                        v-if="isDach && form.country_code"
                        class="col-span-4 sm:col-start-1"
                    >
                        <BaseFieldInput
                            v-model="form.zip"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`${i18nCodePrefix}.zip.label`)"
                            :placeholder="t(`${i18nCodePrefix}.zip.placeholder`)"
                            :vuelidate-field="v$.zip"
                            name="zip"
                        />
                    </div>

                    <div
                        v-if="form.country_code"
                        :class="{ 'col-span-8': isDach, 'col-span-12': !isDach }"
                    >
                        <BaseFieldInput
                            v-model="form.city"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`${i18nCodePrefix}.city.label`)"
                            :placeholder="t(`${i18nCodePrefix}.city.placeholder`)"
                            :vuelidate-field="v$.city"
                            name="city"
                        />
                    </div>
                    <div class="col-span-12">
                        <BaseFieldInput
                            v-model="form.vat_id"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`forms.customer.vat_id.label`)"
                            :vuelidate-field="v$.vat_id"
                            :is-optional="true"
                            name="vat_id"
                        />
                    </div>
                    <div class="col-span-12">
                        <BaseFieldCheck
                            v-model="form.has_agreed_terms"
                            :custom-theme-base="FormTheme.Website"
                            :label="t(`forms.customer.has_agreed_terms.label`)"
                            :vuelidate-field="v$.has_agreed_terms"
                            name="has_agreed_terms"
                        />
                    </div>
                </div>
                <div class="mt-6 px-6">{{ $t('confirmationGeneral.termsOfPayment') }}</div>
            </div>
            <div
                v-else
                data-spec-class="confirmation-form__logged-in-as"
            >
                <UserInfoLoggedIn
                    :user-name="user"
                    :redirect-params="redirectParams"
                />
            </div>

            <Confirm
                class="mt-6 flex"
                :disabled="sendingStatus.busy || sendingStatus.success"
            >
                <BaseSpinner
                    v-if="sendingStatus.busy || sendingStatus.success"
                    class="mr-0.5"
                />
                <span data-spec-class="confirmation-form__submit--order">
                    {{ $t('configuratorGeneral.orderButton') }}
                </span>
            </Confirm>

            <p
                v-if="sendingStatus.error"
                class="mt-3 text-red"
                v-text="sendingStatus.error"
            />
            <p
                v-for="message in sendingStatus.errors"
                class="mt-3 text-red"
                v-text="message.join(', ')"
            />
        </div>
        <div class="honeypot">
            <input
                v-model="form.mail_address"
                type="text"
                :label="$t('honeypot')"
                name="mail_address"
                tabindex="-1"
                autocomplete="off"
            />
        </div>
    </form>
</template>

<script setup>
import { defineProps, reactive, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { UseFormSubmit } from '../functions/useFormSubmit';
import Confirm from './Configurator/Confirm.vue';
import { useAddressSetup } from '../../your/composables/address';
import { useCountryStore } from '../../your/stores/countryStore';
import { FormTheme } from '../../../types';
import { required, mustBeConfirmed, email } from '../../your/i18n/i18n-validators';
import useVuelidate from '@vuelidate/core';
import { useAssortment } from '../composables/assortment';
import UserInfoLoggedIn from './UserInfoLoggedIn.vue';

const {
    fields: addressFields,
    rules: addressRules,
    isDach,
    i18nCodePrefix,
} = useAddressSetup(props.address);
const countryStore = useCountryStore();
const { t } = useI18n();
const { feedGoogleTagManager } = useAssortment();

countryStore.fetchCountries();

const props = defineProps({
    type: { required: true },
    price: { required: true },
    setupPrice: { required: false },
    configuration: { required: false },
    selection: { required: false },
    urlModifier: { default: '' },
    redirectParams: { default: '' },
    orderHint: { default: '' },
    isUsableForUser: { default: false },
});

const rules = computed(() => ({
    ...addressRules.value,
    first_name: { required },
    last_name: { required },
    email: { required, email },
    company: { required },
    has_agreed_terms: {
        mustBeConfirmed: mustBeConfirmed(),
    },
}));

const submitData = reactive({
    configuration: props.configuration,
    type: props.type,
    price: props.price,
    setupPrice: props.setupPrice,
    selection: props.selection,
    has_agreed_terms: false,
    lang: document.documentElement.lang,

    ...addressFields,
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    vat_id: '',
    has_agreed_terms: false,
    mail_address: '',
    is_dach: false,
});

const { form, send, sendingStatus } = UseFormSubmit(
    window.location.href + props.urlModifier,
    submitData,
    () => feedGoogleTagManager(submitData, props.type)
);

const user = document.querySelector('meta[name="user"]').getAttribute('content') || '';

const v$ = useVuelidate(user ? computed(() => ({})) : rules, form);

const onSubmit = async () => {
    v$.value.$touch();
    if (v$.value.$error) {
        return;
    }
    try {
        await send(false);
        if (sendingStatus.success) {
            window.location.href = '/thank-you';
        }
    } catch (error) {}
};

watch(
    () => form.country_code,
    () => {
        addressFields.country_code = form.country_code;
    }
);
</script>
