<template>
    <splide
        :options="options"
        :has-track="false"
        class="mx-auto"
    >
        <SplideTrack>
            <splide-slide v-for="image in images">
                <img
                    :src="image.src"
                    :alt="$t(`imageDescriptions.${image.altI18nKey}`)"
                />
            </splide-slide>
        </SplideTrack>

        <div class="splide__arrows">
            <img
                src="/img/arrows/left.svg"
                alt="Nach Links"
                class="splide__arrow splide__arrow--prev"
            />

            <img
                src="/img/arrows/right.svg"
                alt="Nach Rechts"
                class="splide__arrow splide__arrow--next"
            />
        </div>
    </splide>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';

export default {
    components: {
        Splide,
        SplideSlide,
        SplideTrack,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
        width: {
            type: String,
            default: '1000px',
        },
    },
    data() {
        return {
            options: {
                autoplay: true,
                width: this.width,
                rewind: true,
            },
        };
    },
};
</script>

<style>
.splide__arrow {
    background-color: transparent;
    height: 40px;
    width: 40px;
}
.splide__pagination {
    align-items: flex-end;
    display: flex !important; /* TODO: overwrite ugly .is-overflow splide behaviour */
}
.splide__pagination__page {
    background-color: white;
    border-radius: 4px;
    transition: all 300ms;
    opacity: 1;
    margin: 0 12px;
}
.splide__pagination__page.is-active {
    background-color: #00afcb;
    height: 32px;
}
</style>
