<template>
    <div>
        <label
            :for="name"
            class="block pl-5 text-left"
            >{{ label }}</label
        >
        <textarea
            :id="name"
            :name="name"
            :value="modelValue"
            class="mt-2 h-52 w-full rounded p-4 text-dark"
            :class="{ 'bg-red-light': errors.length }"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <div class="h-9">
            <template v-if="errors">
                <template v-for="error in errors">
                    <p class="pl-5 pt-1 text-sm text-red">
                        {{ error }}
                    </p>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        modelValue: { default: null },
        label: { type: String, required: true },
        errors: { type: Array, default: [] },
    },
    emits: ['update:modelValue'],
};
</script>
