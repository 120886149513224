<template>
    <div data-spec-class="network_configuration_fieldset">
        <ColumnTitle>{{ $t('configuratorGeneral.network') }}</ColumnTitle>
        <Radios
            class="mt-6"
            v-if="uplinks.length > 1"
        >
            <template
                v-for="uplink in uplinks"
                :key="uplink.id"
            >
                <Radio
                    v-model="fields.uplinkId"
                    :label="uplink.name"
                    :value="uplink.id"
                    name="uplink"
                />
            </template>
        </Radios>
        <div class="mt-6">
            <Dropdown
                v-model="fields.volumeId"
                name="volume"
                :label="$t('configuratorGeneral.includedBandwidth')"
                :options="volumeDropdownOptions"
            >
                <template #tooltip>
                    <VolumeTooltip />
                </template>
            </Dropdown>
            <Hint class="mt-3">{{ $t('configuratorGeneral.eachMbit') }}</Hint>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Dropdown from './Configurator/Dropdown.vue';
import Radios from './Configurator/Radios.vue';
import Radio from './Configurator/Radio.vue';
import Hint from './Configurator/Hint.vue';
import VolumeTooltip from './VolumeTooltip.vue';

const props = defineProps({
    uplinks: { type: Array, default: () => [] },
    volumes: { type: Array, required: true },
    fields: { type: Object, required: true },
    selection: { type: Object, required: true },
});

const volumeDropdownOptions = computed(() => {
    const options = {};
    Object.values(props.volumes)
        .filter((volume) => {
            return volume.volume <= props.selection.uplink.max_volume * 1000;
        })
        .forEach((volume) => {
            options[volume.id] = `${volume.volume} Mbit/s`;
        });
    return options;
});
</script>
