<template>
    <BaseAd>
        <template #title>
            {{ $t('configuratorDedicated.specialDealsAdTitle') }}
        </template>

        <span
            v-html="
                $t('configuratorDedicated.specialDealsAdDescription', {
                    href: '/dedicated-server-mieten',
                })
            "
        ></span>
    </BaseAd>
</template>
