<template>
    <template v-if="isDefaultConfig">
        <availability :soon="true">
            {{ $t('configuratorGeneral.available8hrs') }}
        </availability>
    </template>
    <template v-else>
        <availability :soon="false">
            {{ $t('configuratorGeneral.available48hrs') }}
        </availability>
    </template>
</template>

<script>
import Availability from '../Configurator/Availability.vue';

export default {
    components: { Availability },
    props: {
        isDefaultConfig: { type: Boolean, required: true },
    },
};
</script>
