<template>
    <BaseTooltipHelp
        :theme="theme"
        :size="size"
        data-spec-id="tooltip-help-volume"
    >
        {{ t('configuratorGeneral.includedBandwidthHint') }}
        <BaseLink
            class="mt-1"
            :href="articleUrl"
            target="_blank"
        >
            {{ t('actions.moreInfo') }}
        </BaseLink>
    </BaseTooltipHelp>
</template>

<script setup lang="ts">
import { Size, Theme } from '../../../types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface Props {
    size?: Size;
    theme?: Theme;
}

withDefaults(defineProps<Props>(), {
    size: Size.Large,
    theme: Theme.Dark,
});

const articleUrl =
    'https://service.23m.com/hc/articles/7553053939484-What-does-the-95-traffic-calculation-mean';
</script>
