<template>
    <component
        :is="cardTag"
        :href="href"
    >
        <div :class="classObject">
            <div
                v-if="badges.length"
                class="flex space-x-3 pb-2"
            >
                <template v-for="badge in badges">
                    <badge
                        :text="getTranslation(badge, 'name_i18n')"
                        :color="badge.color"
                    />
                </template>
            </div>
            <div class="flex justify-between">
                <div>
                    <slot></slot>
                </div>

                <div
                    v-if="!!$slots.aside"
                    class="text-right"
                >
                    <slot name="aside"> </slot>
                </div>
            </div>
        </div>
    </component>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { getTranslation } from '../../../../utils/i18nUtils';
import Badge from './Badge.vue';

const props = defineProps({
    href: {
        type: String,
        default: undefined,
    },
    badges: {
        type: Array,
        default: () => [],
    },
});

const cardTag = computed(() => {
    if (props.href) {
        return 'a';
    }

    return 'div';
});

const classObject = computed(() => ({
    'rounded border border-gray-500 bg-gray-600 p-3 text-left ring-offset-gray-600': true,
    'cursor-pointer transition duration-300 hover:outline-none hover:ring-2 hover:ring-blue-700 hover:ring-offset-2':
        props.href,
}));
</script>
