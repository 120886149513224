<template>
    <div
        class="mb-11 block"
        data-spec-class="confirmation-form__logged-in-as"
    >
        {{ userName }}

        <div class="text-center text-sm">
            ({{ t('configuratorGeneral.notYou') }}
            <BaseLinkLogout
                :redirect-params="redirectParams"
                class="underline"
            >
            </BaseLinkLogout
            >)
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
    userName: String,
    redirectParams: String,
});
</script>
