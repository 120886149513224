<template>
    <label
        class="block"
        data-spec-class="radio-box"
        :data-spec-id="`radio-box--${title.split(' ').join('-').toLowerCase()}`"
    >
        <input
            v-model="modelValue"
            type="radio"
            :value="value"
            :name="name"
            class="item hidden"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <div class="cursor-pointer rounded border border-gray-500 bg-gray-600 p-3 text-left">
            <template v-if="badges.length">
                <div class="flex space-x-3 pb-2">
                    <template v-for="badge in badges">
                        <badge
                            :text="translateNameI18n(badge, 'name_i18n')"
                            :color="badge.color"
                        />
                    </template>
                </div>
            </template>
            <p class="text-lg text-white">{{ title }}</p>
            <p
                v-if="description"
                :class="['text-xs', modelValue == value ? 'text-white' : 'text-blue-700']"
            >
                {{ description }}
            </p>
            <p
                v-if="properties"
                :class="['text-xs', modelValue == value ? 'text-white' : 'text-gray-300']"
            >
                {{ properties }}
            </p>
        </div>
    </label>
</template>

<script>
import Badge from './Badge.vue';
import { getTranslation } from '../../../../utils/i18nUtils';

export default {
    components: { Badge },
    props: {
        name: { type: String, required: true },
        badges: { required: false, default: [] },
        value: { required: true },
        modelValue: { default: null },
        title: { type: String, required: true },
        description: { type: String, required: false },
        properties: { type: String, required: false },
    },
    methods: {
        translateNameI18n(badge) {
            return getTranslation(badge, 'name_i18n');
        },
    },
    emits: ['update:modelValue'],
};
</script>
