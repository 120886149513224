<template>
    <div>
        <input
            v-model="isActive"
            type="checkbox"
            :name="badge.name"
            class="hidden"
        />

        <button
            :class="classObject"
            :style="styleObject"
            @click="
                () =>
                    $emit(
                        'update:modelValue',
                        isActive
                            ? modelValue.filter((v) => v !== badge.id)
                            : [...modelValue, badge.id]
                    )
            "
        >
            <slot />
        </button>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { contrastColor } from '../../../../utils/colorUtils';

defineEmits(['update:modelValue']);

const props = defineProps({
    badge: { type: Object, required: true },
    modelValue: { default: [] },
});

const isActive = computed(() => props.modelValue.includes(props.badge.id));

const classObject = computed(() => ({
    'rounded-full border border-gray-500 px-2.5 py-1.5 text-center text-xs font-sans transition': true,
    'bg-gray-600 text-white': !isActive.value,
}));

const styleObject = computed(() => ({
    backgroundColor: isActive.value ? props.badge.color : '',
    color: isActive.value ? contrastColor(props.badge.color) : '',
}));

const onClick = () => {
    $emit(
        'update:modelValue',
        isActive ? modelValue.filter((v) => v !== badge.id) : [...modelValue, badge.id]
    );
};
</script>
