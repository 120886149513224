export function managements() {
    return {
        1: {
            name: 'Unmanaged',
            price: 0,
            setupPrice: 0,
        },
        2: {
            name: 'Managed Linux',
            price: 95,
            setupPrice: 100,
        },
        3: {
            name: 'Managed Nextcloud',
            price: 95,
            setupPrice: 100,
        },
    };
}

export function cpu() {
    return {
        min: 1,
        max: 9,
        price: 9.2,
    };
}

export function ram() {
    return {
        min: 4,
        max: 64,
        price: 2.3,
    };
}

export function storage() {
    return {
        min: 25,
        max: 2000,
        price: 0.1729,
    };
}
