<template>
    <form
        class="mx-auto mt-14 w-full rounded bg-gray-700 px-16 pb-14 pt-12 text-left text-white xl:w-7/12"
        @submit.prevent="subscribe"
    >
        <div>
            <string
                v-model="email"
                type="email"
                name="email"
                :label="$t('contact.email')"
                :errors="errors.email"
            />
        </div>
        <button
            class="rounded-full bg-purple-700 px-8 py-1 text-lg text-white transition hover:bg-purple-600"
            v-if="!success"
        >
            {{ $t('newsletter.subscribe') }}
        </button>
        <p
            v-if="success"
            class="mt-3 text-sm text-green"
        >
            {{ $t('newsletter.success') }}
        </p>
    </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import BaseSpinner from '../../../components/BaseSpinner.vue';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import axios from 'axios';
import String from './Form/String.vue';

const email = ref('');
const loading = ref(false);
const success = ref(false);
const errors = ref({});

async function subscribe() {
    loading.value = true;
    success.value = false;
    errors.value = {};

    await axios
        .post(window.location.pathname, {
            email: email.value,
        })
        .then(() => {
            email.value = '';
            loading.value = false;
            success.value = true;
        })
        .catch((err) => {
            errors.value =
                err.response.status === 422
                    ? err.response.data.errors
                    : { email: [__('newsletter.error')] };
        });
}
</script>
