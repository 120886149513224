<template>
    <ul class="divide-y divide-solid divide-gray-500 text-left text-lg">
        <li
            v-for="(summaryGroup, i) in summaryGroups"
            :key="i"
            data-spec-class="summary__position"
        >
            <div
                class="flex justify-between py-1"
                v-for="position in positionsBy(summaryGroup)"
            >
                <div>
                    <div class="flex items-center">
                        {{ position.name }}

                        <component
                            v-if="position.component"
                            :is="position.component"
                        >
                        </component>
                    </div>

                    <div
                        class="-mt-1 text-base text-gray-400"
                        v-html="position.description"
                    ></div>
                </div>

                <div>
                    {{ $n(position.monthlyPrice || 0, 'currency') }}
                </div>
            </div>
        </li>
    </ul>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    summaryGroups: Array,
});

const positionsBy = (summaryGroup) => {
    if (summaryGroup instanceof Array) {
        return summaryGroup;
    }

    return [summaryGroup];
};
</script>
