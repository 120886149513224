<template>
    <div>
        <p class="text-left text-lg text-white">
            {{ label }}
        </p>
        <div class="flex items-center space-x-3">
            <input
                type="range"
                :name="name"
                :value="modelValue"
                :min="min"
                :max="max"
                class="grow"
                @input="emit"
            />
            <input
                type="number"
                :name="name"
                :value="modelValue"
                :min="min"
                :max="max"
                class="w-16 rounded border border-gray-500 bg-gray-700 p-2 text-center text-white"
                @input="emit"
                @click="$event.target.select()"
            />
            <div class="text-white">
                {{ unit }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        modelValue: { default: null },
        label: { type: String, required: true },
        unit: { type: String, default: '' },
        min: { type: Number, default: 1 },
        max: { type: Number, required: true },
    },
    methods: {
        emit(event) {
            let { value } = event.target;
            if (value < this.min) {
                value = this.min;
            }
            if (value > this.max) {
                value = this.max;
            }
            this.$emit('update:modelValue', value);
        },
    },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
