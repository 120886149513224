<template>
    <p
        :class="amount > 0 ? 'text-green' : 'text-white'"
        class="mt-6 text-center"
    >
        {{ $t('configuratorDedicated.slotsRemaining', { count: amount }) }}
    </p>
</template>

<script>
export default {
    props: {
        amount: { type: Number, required: true },
    },
};
</script>
