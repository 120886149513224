import { reactive } from 'vue';

export function UseFormSubmit(url, fields, afterSuccess = () => {}) {
    const form = reactive(fields);

    const sendingStatus = reactive({
        busy: false,
        success: null,
        error: null,
        errors: {},
    });

    const send = async (areFieldsResetable = true) => {
        resetMessages();
        sendingStatus.busy = true;
        await axios
            .post(url, form)
            .then((res) => {
                // message sent
                sendingStatus.success = res.data.message;
                afterSuccess(form);
                if (areFieldsResetable) resetFields();
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    // validation errors
                    sendingStatus.errors = error.response.data.errors;
                } else if (error.response.status === 429) {
                    // too many requests
                    sendingStatus.error = __('errors.tooManyRequests');
                } else {
                    // error while sending
                    sendingStatus.error = __('errors.error');
                }
            })
            .then(() => {
                sendingStatus.busy = false;
            });
    };

    const resetMessages = () => {
        sendingStatus.errors = {};
        sendingStatus.error = null;
        sendingStatus.success = null;
    };

    const resetFields = () => {
        for (const key in fields) {
            fields[key] = '';
        }
    };

    return {
        form,
        sendingStatus,
        send,
    };
}
