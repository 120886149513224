<template>
    <SummaryGuest
        v-if="!userName"
        :summary-groups="summaryGroups"
        :monthly-price="monthlyPrice"
        :setup-price="setupPrice"
    />
    <SummaryUser
        v-else
        :user-name="userName"
        :summary-groups="summaryGroups"
        :type="type"
        :monthly-price="monthlyPrice"
        :setup-price="setupPrice"
        :mail-summary="mailSummary"
        :selection="selection"
        :redirect-params="redirectParams"
    />
</template>

<script setup>
import { defineProps } from 'vue';
import SummaryGuest from './SummaryGuest.vue';
import SummaryUser from './SummaryUser.vue';

const props = defineProps({
    summaryGroups: Array,
    type: { required: true },
    monthlyPrice: { required: true },
    setupPrice: { required: true },
    mailSummary: { required: true },
    selection: { required: true },
    redirectParams: { default: '' },
});

const userName = document.querySelector('meta[name="user"]').getAttribute('content') || '';
</script>
