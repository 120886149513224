<template>
    <label class="block">
        <input
            v-model="modelValue"
            type="hidden"
            :name="name"
            class="hidden"
        />
        <div class="flex items-center justify-center">
            <div
                :class="{
                    'text-gray-500': !canBeDecreased,
                    'cursor-pointer text-white': canBeDecreased,
                }"
                class="bg-none p-6 text-xl"
                @click.prevent="canBeDecreased ? emits('decrease') : () => {}"
            >
                –
            </div>

            <div
                class="w-24 rounded border-2 border-blue-700 bg-gray-600 px-1 py-2 text-center text-white"
            >
                {{ modelValue }} W
            </div>

            <div
                :class="{
                    'text-gray-500': !canBeIncreased,
                    'cursor-pointer text-white': canBeIncreased,
                }"
                class="bg-none p-6 text-xl"
                @click.prevent="canBeIncreased ? emits('increase') : () => {}"
            >
                +
            </div>
        </div>
    </label>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
    name: { type: String, required: true },
    modelValue: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: Infinity },
});

const emits = defineEmits(['decrease', 'increase']);

const canBeIncreased = computed(() => props.modelValue < props.max);
const canBeDecreased = computed(() => props.modelValue > props.min);
</script>
