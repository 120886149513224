<template>
    <div>
        <input
            v-model="modelValue"
            type="number"
            :name="name"
            class="hidden"
        />
        <div class="flex items-center space-x-6">
            <button
                :class="isActive ? 'bg-blue-700' : 'bg-gray-600'"
                class="flex-1 space-y-2 rounded border border-gray-500 px-3 py-2 text-center text-white"
                @click.prevent="increase"
            >
                <slot />
            </button>
            <div class="flex items-center space-x-3 text-lg">
                <template v-if="canBeDecreased">
                    <button
                        class="bg-none text-xl text-white"
                        @click.prevent="decrease"
                    >
                        –
                    </button>
                </template>
                <template v-else>
                    <span class="text-xl text-gray-500">–</span>
                </template>
                <span class="w-3 text-center text-white">{{ modelValue }}</span>
                <template v-if="canBeIncreased">
                    <button
                        class="bg-none text-xl text-white"
                        @click.prevent="increase"
                    >
                        +
                    </button>
                </template>
                <template v-else>
                    <span class="text-xl text-gray-500">+</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        modelValue: { default: 0 },
        min: { type: Number, default: 0 },
        max: { type: Number, default: null },
    },
    emits: ['decrease', 'increase'],
    computed: {
        isActive() {
            return this.modelValue !== 0;
        },
        canBeDecreased() {
            return this.modelValue > this.min;
        },
        canBeIncreased() {
            if (this.max === null) {
                return true;
            }
            return this.modelValue < this.max;
        },
    },
    methods: {
        decrease() {
            this.$emit('decrease');
        },
        increase() {
            this.$emit('increase');
        },
    },
};
</script>
