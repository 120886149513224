<template>
    <div class="text-left">
        <label class="flex">
            <input
                type="checkbox"
                :name="name"
                class="rounded border border-gray-400 bg-gray-600"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <div class="ml-2 text-gray-300">{{ label }}</div>
        </label>
        <div class="h-9">
            <template v-if="errors">
                <template v-for="error in errors">
                    <p class="pl-5 pt-1 text-sm text-red">
                        {{ error }}
                    </p>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: false },
        modelValue: { default: null },
        label: { type: String, required: true },
        errors: { type: Array, default: [] },
    },
    emits: ['update:modelValue'],
};
</script>
