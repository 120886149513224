<template>
    <div
        id="configurator-form--s3"
        class="flex justify-center"
        data-spec-id="configurator-form--s3"
    >
        <ConfirmationForm
            :type="AssortmentType.S3"
            class="w-full lg:w-2/3 xl:w-1/2"
            :price="price"
            configuration=""
            url-modifier="/confirm"
            redirect-params="?redirect=s3-object-storage&scroll=configurator-form--s3"
            :order-hint="$t('configuratorS3.orderHint')"
            :is-usable-for-user="true"
        />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import ConfirmationForm from './ConfirmationForm.vue';
import { useAssortmentS3 } from '../composables/assortmentS3';
import { AssortmentType } from '../../../types';

const props = defineProps({
    assortmentContent: Object,
});

const { price } = useAssortmentS3(props.assortmentContent);

const userName = document.querySelector('meta[name="user"]').getAttribute('content') || '';
</script>
