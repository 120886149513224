export function networks() {
    return {
        1: { id: 1, name: '1 GE Uplink', price: 0, maxVolume: 1000 },
        2: { id: 2, name: '10 GE Uplink', price: 0, maxVolume: 10000 },
    };
}

export function volumes() {
    return {
        1: { id: 1, volume: 250, price: 0 },
        2: { id: 2, volume: 500, price: 30 },
        3: { id: 3, volume: 750, price: 60 },
        4: { id: 4, volume: 1000, price: 90 },
        5: { id: 5, volume: 2500, price: 270 },
        6: { id: 6, volume: 5000, price: 570 },
        7: { id: 7, volume: 7500, price: 870 },
        8: { id: 8, volume: 10000, price: 1170 },
    };
}

export function volumesCloud() {
    return {
        1: { id: 1, volume: 1, price: 0 },
        2: { id: 2, volume: 10, price: 35 },
        3: { id: 3, volume: 25, price: 75 },
        4: { id: 4, volume: 50, price: 150 },
        5: { id: 5, volume: 100, price: 350 },
    };
}

export function ipv4Address() {
    return {
        price: 1.5,
        setupPrice: 15,
        min: 0,
        max: 10,
        free: 1,
    };
}

export function ipv4Subnets() {
    return {
        1: {
            name: '/28',
            addresses: 14,
            price: 21,
            setup: 210,
        },
        2: {
            name: '/27',
            addresses: 30,
            price: 45,
            setup: 450,
        },
        3: {
            name: '/26',
            addresses: 62,
            price: 93,
            setup: 930,
        },
        4: {
            name: '/25',
            addresses: 126,
            price: 189,
            setup: 1890,
        },
        5: {
            name: '/24',
            addresses: 254,
            price: 381,
            setup: 3810,
        },
    };
}

export function ipv6Subnets() {
    return {
        1: {
            name: '/64',
            addresses: '2^64',
            price: 0,
            setup: 0,
        },
    };
}
