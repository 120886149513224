<template>
    <div class="mt-6 text-center lg:text-right">
        <div>
            <div>
                <span class="text-2xl font-bold text-blue-700">
                    {{ $n(monthlyPrice, 'currency') }}
                </span>
                <div
                    class="text-base text-gray-400"
                    :class="{ '!text-gray-300': user }"
                >
                    {{ $t('configuratorGeneral.perMonth') }}
                </div>
            </div>

            <p
                v-if="setupPrice"
                class="mt-3 flex justify-center text-gray-400 lg:justify-end"
                :class="{ '!text-gray-300': user }"
            >
                {{
                    $t('configuratorGeneral.setupAdditional', {
                        setupFee: $n(setupPrice, 'currency'),
                    })
                }}
                <BaseTooltipHelp
                    :size="Size.Large"
                    class="ml-1 h-5 w-5"
                >
                    <div
                        v-for="(position, i) in positionsWithSetupPrices"
                        :key="i"
                    >
                        {{ position.name }}: {{ $n(position.setupPrice, 'currency') }}
                    </div>
                </BaseTooltipHelp>
            </p>
            <p
                class="mt-3 text-gray-400"
                :class="{ '!text-gray-300': user }"
            >
                ({{ $t('configuratorGeneral.pricesPlusVat') }})
            </p>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { Size } from '../../../../types';

const props = defineProps({
    summaryGroups: Array,
    monthlyPrice: { required: true },
    setupPrice: { required: true },
});

const positionsWithSetupPrices = computed(() => {
    return props.summaryGroups.flat().filter((position) => position.setupPrice > 0);
});
</script>
