<template>
    <BaseAd>
        <template #title>
            {{ $t('configuratorSpecialDeals.dedicatedAdTitle') }}
        </template>

        <span
            v-html="
                $t('configuratorSpecialDeals.dedicatedAdDescription', {
                    hrefDedicated: '/dedicated-server',
                    hrefContact: '/contact',
                })
            "
        ></span>
    </BaseAd>
</template>
