<template>
    <label>
        <input
            v-model="modelValue"
            type="radio"
            :value="value"
            :name="name"
            class="item hidden"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <div class="cursor-pointer space-y-2 rounded border border-gray-500 bg-gray-600 p-3">
            <p class="text-center text-lg text-white">{{ label }}</p>
        </div>
    </label>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        value: { required: true },
        modelValue: { default: null },
        label: { type: String, required: true },
    },
    emits: ['update:modelValue'],
};
</script>
